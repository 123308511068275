import React from 'react';
//import  { useState, useEffect } from 'react';
import { Container} from '@material-ui/core';
import OrdersList from './ordersList';
import Copyright from '../Copyright';



export default function OrderStatus(props) {  

    const IsEmbbed = localStorage.getItem('IsEmbedded')!==null && localStorage.getItem('IsEmbedded')==='yes';
    const account = localStorage.getItem('current_accountNumber')!== '' ? localStorage.getItem('current_accountNumber'):localStorage.getItem('accountNumber');  
  
    return (
        <div className='container_orders-list'>
        <Container maxWidth="false">  
            <div style = {{ height: "100%", width: "100vw" }}>              
                <OrdersList account={account} isEmbedded={IsEmbbed}/>  
            </div>
            {!IsEmbbed && <footer><Copyright/></footer>}
        </Container>    
        </div>  
    );
   

}

