import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import {Helmet} from "react-helmet";
import ReHelmet from "./Helmet";

ReactDOM.render(
    <>
        {/* <Helmet>
            <meta charSet="utf-8" />
            <title>DirectIt Order Entry</title>            
        </Helmet> */}
        <ReHelmet title="DirectIt Order Entry" />
        <App />
    </>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
