import React from 'react';
import { useState, useEffect } from 'react';
import { Grid,Container,Button} from '@material-ui/core';
import Copyright from '../Copyright';
import Panel from '../orderStatus/components/whitePanel';
import SelectSearchTypeOptions from './SelectSearchTypeOptions';
import styles from '../newOrder/components/pickUpDeliveryTime/styles';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

import HistoryOrdersList from './HistoryOrderList.js';
import httpRequest from '../HttpService';
import { getStatusString, getLocationString } from '../newOrder/components/helperFunctions/index.js';

export default function OrderHistory(props)  {
    const [orders, setOrders] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchMonth, setSearchMonth] = useState(new Date());
    const [searchType, setSearchType] = useState('month');   

    const account =  localStorage.getItem('current_accountNumber')!== '' ? localStorage.getItem('current_accountNumber'):localStorage.getItem('accountNumber');
    const IsEmbbed = localStorage.getItem('IsEmbedded')!==null && localStorage.getItem('IsEmbedded')==='yes';   

    useEffect(() => {         
        const searchType = localStorage.getItem('searchHistory');
        if(searchType === 'no') return;
        setSearchType(searchType);       
        var search ={}
        if(searchType === 'month'){
            search.monthDate = localStorage.getItem('monthDate'); 
            setSearchMonth(localStorage.getItem('monthDate'));              
        }
        if(searchType === 'date'){
            search.startDate = localStorage.getItem('startDate');
            search.endDate = localStorage.getItem('endDate');
            setStartDate(localStorage.getItem('startDate'));
            setEndDate(localStorage.getItem('endDate')); 
        }

        httpRequest.orderSearch(account, search).then(async (body) =>{    
            console.log("orderSearch return-" + JSON.stringify(body.data));  
            let orderList =  body.data.map((value,index) => {
                value.id = index;
                value.statusStr = getStatusString(value.Status);
                value.locationStr = getLocationString(value.location);
                value.dtCallIn_UTC = moment(value.dtCallIn_UTC).format('YYYY-MM-DD HH:mm');
                value.dtDCT_UTC = moment(value.dtDCT_UTC).format('YYYY-MM-DD HH:mm');
                if(value.dtDelivered_UTC)
                    value.dtDelivered_UTC = moment(value.dtDelivered_UTC).format('YYYY-MM-DD HH:mm');
                return value;
            });
            setOrders(orderList);             
        });   
    },[]);
        
    
    // handleChange = (event) => {
    //      console.log(event.target.name, event.target.value);
    //     const { name, value } = event.target;
    //     this.setState({[name]: value});
    // };

    const handleSearch = () => { 
        localStorage.setItem('searchHistory', searchType); 
        const orderList=[];
        var search ={};
        if(searchType === "month"){
            //search.monthDate = moment(searchMonth).unix();
            search.monthDate =searchMonth;
            localStorage.setItem('monthDate', searchMonth); 
           // moment(queryParams.endDate).unix();
        }
        else{
            //search.startDate = moment(startDate).unix();
            //search.endDate = moment(endDate).unix();
            search.startDate = startDate;
            search.endDate = endDate;
            localStorage.setItem('startDate', startDate); 
            localStorage.setItem('endDate',endDate); 
        }
        console.log( "handleSearch is " + JSON.stringify(search));    
        
        httpRequest.orderSearch(account, search).then(async (body) =>{    
            console.log("orderSearch return-" + JSON.stringify(body.data));                
            let orderList =  body.data.map((value,index) => {
                value.id = index;
                value.statusStr = getStatusString(value.Status);
                value.locationStr = getLocationString(value.location);
                value.dtCallIn_UTC = moment(value.dtCallIn_UTC).format('YYYY-MM-DD HH:mm');
                value.dtDCT_UTC = moment(value.dtDCT_UTC).format('YYYY-MM-DD HH:mm');
                if(value.dtDelivered_UTC)
                    value.dtDelivered_UTC = moment(value.dtDelivered_UTC).format('YYYY-MM-DD HH:mm');
                return value;
            });
            setOrders(orderList);           
        });       
    };  

        return (         
            <Container maxWidth="false">               
                <Grid container>
                    <Grid item md={12}>
                        <Panel>
                            <Grid container>
                                <Grid item md={2}> 
                                   <SelectSearchTypeOptions  onChange={(event)=>{setSearchType(event.target.value)}} name="searchType"/>              
                                </Grid>
                                 <Grid item md={2}>  
                                   <div  class="animate__animated animate__fadeInUp">      
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justifyContent="left">
                                            {searchType==='month' && <KeyboardDatePicker 
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    size="small"
                                                    value={searchMonth}
                                                    onChange={(date) => {      
                                                        console.log("month date is " + date);    
                                                        setSearchMonth(date);
                                                    }}
                                                    onError={console.log}
                                                    openTo = "year"
                                                    views={["year", "month"]}
                                                    format="yyyy-MM"
                                                    shouldDisableDate = {true}
                                                    style={styles.dateTimePicker}
                                                    KeyboardButtonProps={{
                                                    'aria-label': 'change month',
                                                     }}
                                                />}                                                
                                                
                                                {searchType==='date' && <KeyboardDatePicker
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    size="small"
                                                    value={startDate}
                                                    onChange={(date) => {  
                                                        console.log("start date is " + date);   
                                                        setStartDate(date);        
                                                    }}
                                                    onError={console.log}
                                                    format="yyyy-MM-dd"
                                                    style={styles.dateTimePicker}
                                                    KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                     }}
                                                />}
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>                               
                                </Grid>
                                <Grid item md={2}>  
                                       {searchType==='date' && <div  class="animate__animated animate__fadeInUp">      
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justifyContent="left">
                                                <KeyboardDatePicker
                                                    inputVariant="outlined"
                                                    variant="inline"
                                                    ampm={true}
                                                    size="small"
                                                    value={endDate}
                                                    onChange={(date) => {      
                                                        console.log("end date is " + moment(date).unix());   
                                                        setEndDate(date); 
                                                       }                                                    
                                                    }
                                                    onError={console.log}
                                                    format="yyyy-MM-dd"
                                                    style={styles.dateTimePicker}
                                                    KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                     }}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>  }                                                            
                                </Grid>
                                <Grid item md={6}>
                                    <Button
                                       style={{maxWidth:"250px", minWidth:"200px", float:"right"}}
                                       variant="contained"                           
                                       color="primary" 
                                       startIcon={<SearchIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}
                                       onClick={handleSearch}
                                    >
                                    Search
                                    </Button>                                  
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                    
                </Grid>   
                {/* {orders.length>0 &&  <div style = {{height: '90vh', width: '100%'}}> */}
                {orders.length>0 &&  <div className='container_history-list'>                    
                      <HistoryOrdersList orders={orders} account={account}/>
                    </div>
                }
                {!IsEmbbed && <footer> <Copyright/> </footer>}
            </Container>  
            // </div>      
        );
    
}


